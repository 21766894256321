<template>
  <div>
    <!--    <v-autocomplete auto-select-first @input="autoModel= ''" v-if="showTypeahead" @change="onAutoComplete" :items="uncheckedOptionObjects" item-text="title" item-value="extName" v-model="autoModel" :delimiters="[';']">-->

    <div v-if="showIncludeExclude">
      <v-select v-model="includeExcludeMode" :items="[{value: 'included', text: $t('FILTER_INCLUDED')}, {value: 'excluded', text: $t('FILTER_EXCLUDED')}]"></v-select>
    </div>

    <v-autocomplete
        :search-input.sync="search"
        v-if="showTypeahead"
        @change="onAutoComplete"
        :items="uncheckedOptionObjects"
        item-text="title"
        item-value="extName"
        v-model="autoModel">

      <template v-slot:prepend-item v-if="searchMatch && filter.submatch">
        <v-list-item  @click="onPlusMinusClicked(search)">
          <v-list-item-content>
            <v-list-item-title>
              {{ search }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div style="display: flex">
              <v-icon >mdi-plus</v-icon>
            </div>
          </v-list-item-action>
        </v-list-item>
      </template>

      <template v-slot:no-data v-if="filter.submatch">
        <v-list-item  @click="onPlusMinusClicked(search)">
          <v-list-item-content>
            <v-list-item-title>
              {{ search }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div style="display: flex">
              <v-icon >mdi-plus</v-icon>
            </div>
          </v-list-item-action>

        </v-list-item>
      </template>

    </v-autocomplete>

    <v-list dense>
      <v-list-item dense v-for="option in listedOptions" :key="option.extName">
        <v-list-item-content>
          <v-checkbox dense hide-details class="filterCheckBox ma-0 pa-0" :label="option.title" v-model="checkedOptions"
                      :value="option.extName" @change="checkClicked"></v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-list>


  </div>
</template>

<script>
import {mapState} from "vuex";
import _ from 'lodash'

export default {
  name: 'FilterComponentChecks',

  data() {
    return {
      includeExcludeMode: null,
      search: "",
      autoModel: null,
      checkedOptions: [],
      checkedSubstringOptions: []
    }
  },

  props: {
    filter: Object
  },

  computed: {
    ...mapState(['combinedFilters']),

    includeMode() {
       return this.includeExcludeMode==='included'
    },

    searchMatch() {
      if(this.search) {
        const searchResult = this.uncheckedOptionObjects.filter( option => option.title.match( new RegExp(this.search, 'i')  ) ).length>0
        return searchResult
      }

      return false
    },

    showIncludeExclude() {
      return this.showTypeahead && this.filter.exclude
    },

    showTypeahead() {
      if (this.filter.dropdown !== null) {
        return this.filter.dropdown
      }

      return this.filter.options.length >= 10
    },

    listedOptions() {
      if (this.showTypeahead) {
        return this.checkedOptionObjects
      }

      return this.filter.options
    },

    checkedOptionObjects() {
      return this.filter.options.filter(o => this.checkedOptions.includes(o.extName)).concat( this.checkedSubstringOptions )

      //
      // return this.filter.options.filter(o => this.checkedOptions.includes(o.extName))
    },

    uncheckedOptionObjects() {
      return this.filter.options.filter(o => !this.checkedOptions.includes(o.extName))
    }


  },

  created() {
    this.checkedOptions = []
    this.checkedSubstringOptions = []
    this.includeExcludeMode = 'included'
  },

  methods: {
    isSelected(extname) {
      return this.checkedOptions.filter(o => o===extname).length>0
    },

    onPlusMinusClicked(o) {
      // console.log("PlusMinus: " + o)

      const extname = this.includeMode ? o + "~" : "_" + o + "~"
      const title = this.includeMode ? this.$t('FILTER_PLUS') + " " + o : this.$t('FILTER_MINUS') + " " +o

      if(this.isSelected(extname)) {
        // console.log("plus Was selected")
        return
      }

      this.checkedOptions.push( extname )
      this.checkedSubstringOptions.push({extName: extname, title: title })

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
    },

    onAutoComplete(o) {

      if(o===null) {
        return;
      }

      const extname = this.includeMode ? o : "_"+o
      this.checkedOptions.push(extname)

      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
      this.autoModel = null
    },

    checkClicked() {
      // console.log("checkClicked")
      this.$store.commit('setFilter', {filterName: this.filter.extName, filterValue: this.checkedOptions})
    }
  },


  watch: {
    combinedFilters: {
      handler() {
        let filterValues = this.combinedFilters.getFilterValuesForFilter(this.filter.extName);

        if (!_.isEqual(filterValues, this.checkedOptions)) {
          this.checkedOptions = [...filterValues]
        }

      },
      deep: true,
      immediate: true
    },

  },


}
</script>

<style>
.filterCheckBox {
}

div.filterCheckBox div.v-input__control div.v-input__slot {
  margin: 0 !important;
  font-size: calc((13.5 / 16) * 1rem) !important;
}

div.filterCheckBox label {
  left: 6px !important;
}

div.filterCheckBox label {
  font-size: calc((13.5 / 16) * 1rem) !important;

}

.v-select-list .v-list .v-input__slot {
  margin-bottom: 0 !important;
}

</style>
