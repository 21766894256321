<template>
  <v-card outlined>
    <v-card-title>Tasks</v-card-title>

    <v-container fluid>

      <v-row>
        <v-col cols="2" >
          <v-select label="state" :items="taskStates"  v-model="taskState" @change="fetchTasks()"></v-select>
        </v-col>
        <v-col cols="2" >
          <v-select label="type" :items="taskTypes"  v-model="taskType" @change="fetchTasks()"></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>

          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">Type</th>
              <th class="text-left">State</th>
              <th class="text-left">Priority</th>
              <th class="text-left">Created</th>
              <th class="text-left">Updated</th>
              <th class="text-left">Scheduled</th>
              <th class="text-left">Reason</th>
              <th class="text-left">Description</th>
              <th class="text-left">Action</th>
            </tr>

            </thead>
            <tbody>
            <tr v-for="(task, idx) in taskList" :key="task.id">
              <td>{{task.type}}</td>
              <td>{{task.state}}</td>
              <td>{{task.priority}}</td>
              <td>{{task.created}}</td>
              <td>{{task.updated}}</td>
              <td>{{task.scheduled}}</td>
              <td>{{task.reason}}</td>
              <td>{{task.description}}</td>
              <td><v-btn @click="onProcess(task.id, idx)">process</v-btn></td>
            </tr>
            </tbody>
          </v-simple-table>



        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select label="assigning state" :items="taskStates"  v-model="assignState"></v-select>
          <v-btn :disabled="assignState===''" @click="onAssignState()">assign</v-btn>
          <v-btn @click="onDeleteSelected">delete selected</v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from "vuex";
import SurveysService from "@/services/SurveysService";

export default {
  name: 'TaskList',

  components: {},

  data: () => ({
    taskList: [],
    taskState: "",
    taskType: "",
    assignState: ""
  }),



  props: {
    // alertData: Object
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey']),

    taskStates() {
      return [ "", "OPEN", "PROCESSING", "CLOSED", "FAILED", "RETRY", "PAUSED" ]
    },

    taskTypes() {
      return [
        "",
        "BULK_MAIL",
        "TEST",
        "SOLSTRAND_INTEGRATION",
        "SOLSTRAND_GEOCODING",
        "OVERVIEW_REPORT",
        "STATUS_MAIL",
        "PORSCHE_INTEGRATION",
        "SURVEYGIZMO_CONTACT_CREATE",
        "SURVEYGIZMO_CONTACT_UPDATE",
        "SAMPLES_REPORT",
        "CORE_REPORT",
        "ALERTS_REPORT",
        "ACTIONS_REPORT",
        "RANKING_REPORT",
        "INTERVIEWS_REPORT",
        "SURVEYGIZMO_SAMPLE_COMPLETE",
        "SEMLER_INTEGRATION",
        "ALERT_MAIL",
        "NOTIFICATION_MAIL",
        "ACTION_MAIL",
        "MAIL_MERGE",
        "RANKINGLIST_REPORT",
        "IMPORT_INTERVIEWS",
        "AUTODESKTOP_INTEGRATION"]
    }


  },

  created() {
//    this.fetchTasks()
  },

  methods: {

    fetchTasks() {
      SurveysService.fetchTasks(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, this.taskState, this.taskType).then((response) => {
        this.taskList = response.data
      })
    },

    onProcess(taskId, idx) {
      SurveysService.processTask(this.getAuthToken, this.getActiveSurvey.extName, taskId).then((response) => {
        Vue.set(this.taskList, idx, response.data)
      })

    },

    onAssignState() {
      SurveysService.assignState(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, this.taskState, this.taskType, this.assignState).then((response) => {
        this.taskList = response.data
      })
    },

    onDeleteSelected() {
      SurveysService.deleteTasks(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId, this.taskState, this.taskType).then(() => {
        this.taskList = []
      })
    }

  }


}
</script>

<style scoped>
</style>