<template>

  <v-container class="pa-0 ma-0">
    <v-row class="pa-0 ma-0" justify="center" align="center">
      <!-- Download progress -->
      <v-col v-if="showDownloadStatus" class="pa-0 ma-0">
        <IconTextButtonComponent
            v-if="showDownloadProgress"
            class="mr-0 pr-0"
            label="Downloading"
            color="primary"
            circularProgress="true"
            :progressMessage="downloadPosition"
            :progressMessageTooltip="$t('DOWNLOAD_POSITION_TOOPTIP')"
        />
        <IconTextButtonComponent
              v-if="showDownloadButton"
              class="mr-0 pr-0"
              icon="mdi-tray-arrow-down" :label="$t('DOWNLOAD')"
              color="#0000c8"
              @click="downloadReport"/>
      </v-col>

      <!-- Download menu -->
      <v-col class="pa-0 ma-0">
        <v-menu class="reportMenu">
          <template v-slot:activator="{ on }">

            <!--            <div class="downloadButton">-->
            <div v-on="on">
              <IconTextButtonComponent :icon="downloadMenuIcon" :label="downloadMenuLabel" :color="downloadMenuColor"
                                       second-icon="mdi-chevron-down"/>
            </div>
            <!--        <div v-on="on">-->
            <!--          <v-btn icon><v-icon color="#757575">mdi-download</v-icon></v-btn>-->
            <!--        </div>-->
            <!--            </div>-->
          </template>

          <v-list class="downloadlist">
            <template v-if="interviewId">
              <v-list-item>
                <v-list-item-title><a class="pdfLink" target="_blank"
                                      :href="individualFlexInterviewDownloadLink">{{ 'INDIVIDUAL_REPORT' | i18n }}</a>
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-else-if="sampleId">
              <v-list-item @click="onGenerateSamplesReport">
                <v-list-item-title>{{ 'DOWNLOAD_SAMPLES_REPORT' | i18n }}</v-list-item-title>
              </v-list-item>
            </template>

            <template v-else>

              <v-list-item v-if='getEnableExcelBenchmarkReport' @click="onGenerateExcelReport">
                <v-list-item-title>{{ 'DOWNLOAD_EXCEL_BENCHMARK_REPORT' | i18n }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if='getEnableInterviewsReport' @click="onGenerateInterviewsReport">
                <v-list-item-title>{{ 'DOWNLOAD_INTERVIEWS_REPORT' | i18n }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if='getEnableAlertsReport' @click="onGenerateAlertsReport">
                <v-list-item-title>{{ 'DOWNLOAD_ALERT_REPORT' | i18n }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if='getEnableActionsReport' @click="onGenerateActionsReport">
                <v-list-item-title>{{ 'DOWNLOAD_ACTION_REPORT' | i18n }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if='getEnableSamplesReport' @click="onGenerateSamplesReport">
                <v-list-item-title>{{ 'DOWNLOAD_SAMPLES_REPORT' | i18n }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if='getEnableIndicatorsReport' @click="onGenerateIndicatorsReport">
                <v-list-item-title><a class="pdfLink" target="_blank"
                                      :href="indicatorsDownloadLink">{{ 'DOWNLOAD_INDICATORS_REPORT' | i18n }}</a>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if='getEnablePDFBenchmarkReport'>
                <v-list-item-title><a class="pdfLink" target="_blank"
                                      :href="pdfDownloadLink">{{ 'DOWNLOAD_PDF_BENCHMARK_REPORT' | i18n }}</a>
                </v-list-item-title>
              </v-list-item>

<!--              <v-list-item v-if="rankingListReport">-->
<!--                <v-list-item-title><a class="pdfLink" target="_blank" :href="rankingListReportDownloadLink">{{'DOWNLOAD_RANKINGLIST_REPORT' | i18n}}</a>-->
<!--                </v-list-item-title>-->
<!--              </v-list-item>-->

              <v-list-item v-for="reportInfo in activeSurvey.reports" :key="reportInfo.extName" @click="onGenerateReport(reportInfo.extName)">
                <v-list-item-title>{{ reportInfo.title }}</v-list-item-title>
              </v-list-item>

            </template>


          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ReportsService from "../services/ReportsService";
import IconTextButtonComponent from "../components/IconTextButtonComponent"

export default {
  name: 'ReportDownloadMenuComponent',
  components: {
    IconTextButtonComponent
  },
  data() {
    return {
      items: []
    }
  },
  props: {
    interviewId: Number,
    sampleId: Number,
    rankingListReport: Boolean
  },

  computed: {
    ...mapState(['activeSurvey', 'userIdentity']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getCompatibleFilteredDrillPath', 'isUserRoot', 'getEnableExcelBenchmarkReport', 'getEnableInterviewsReport', 'getEnableAlertsReport', 'getEnableActionsReport', 'getEnableSamplesReport', 'getEnableIndicatorsReport', 'getEnablePDFBenchmarkReport']),


    pdfDownloadLink() {
      return ReportsService.overviewReportDownloadURL(this.getAuthToken, this.activeSurvey.extName, this.$store.getters.getFilteredDrillPath)
//      return ReportsService.overviewReportDownloadURL(this.getAuthToken, this.activeSurvey.extName, this.getCompatibleFilteredDrillPath)
    },

    indicatorsDownloadLink() {
      return ReportsService.indicatorsReportURL(this.$store.getters.getAuthToken, this.$store.getters.getFilteredDrillPath)
    },

    rankingListReportDownloadLink() {
      return ReportsService.rankingListReportURL(this.$store.getters.getAuthToken, this.activeSurvey.extName, this.$store.getters.getFilteredDrillPath)
    },

    individualInterviewDownloadLink() {
      // https://dashboard.aganalytics.dk/dashboard/service/download?uid=14078&t=ir&i=11019762
      return ReportsService.individualInterviewReportDownloadURL(this.getAuthToken, this.interviewId)
    },

    individualFlexInterviewDownloadLink() {
      return ReportsService.individualFlexInterviewReportDownloadURL(this.getAuthToken, this.interviewId, this.$store.getters.getFilteredDrillPath)
    },

    downloadMenuColor() {
      return this.$store.state.downloadStatus.state.preparingReport ? '#0000c8' : '#757575'
    },
    downloadMenuIcon() {
      return this.$store.state.downloadStatus.state.preparingReport ? 'mdi-progress-download' : 'mdi-download'
    },
    downloadMenuLabel() {
      return this.$t("REPORT_DOWNLOAD_MENU_LABEL")
    },
    showDownloadStatus() {
      return this.$store.state.downloadStatus.state.downloading
    },
    showDownloadProgress() {
      return this.$store.state.downloadStatus.state.preparingReport
    },
    showDownloadButton() {
      return this.$store.state.downloadStatus.state.downloadReady
    },
    downloadPosition() {
      return this.$store.state.downloadStatus.positionInQueue
    }
  },

  methods: {
    onGenerateExcelReport: function () {
      // console.log('onGenerateExcelReport')
      ReportsService.createRankingReportTask(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store, this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })
    },

    onGenerateInterviewsReport: function () {
      ReportsService.createInterviewsReportTask(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store,  this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })
    },


    onGenerateAlertsReport: function () {
      ReportsService.createAlertsReportTask(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store, this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })
    },

    onGenerateActionsReport: function () {
      ReportsService.createActionsReportTask(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store,  this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })
    },

    onGenerateSamplesReport: function () {
      ReportsService.createSamplesReportTask(this.getAuthToken, this.getActiveSurvey.extName, this.getFilteredDrillPath, this.sampleId).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store, this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })
    },

    onGenerateIndicatorsReport: function () {
      window.open(ReportsService.indicatorsReportURL(this.$store.getters.getAuthToken, this.$store.getters.getFilteredDrillPath), '_blank')

    },

    onGenerateReport(reportName) {
      ReportsService.createReportTask(this.getAuthToken, reportName, this.getActiveSurvey.extName, this.getFilteredDrillPath).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store, this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })

    },

    downloadReport() {
//      const params = "uid=" + this.userIdentity.userId + "&t=task" + "&reportid=" + this.$store.state.downloadStatus.reportUUID
//      const params = "uid=" + this.userIdentity.userId + "&t=task" + "&reportid=" + this.$store.state.downloadStatus.reportUUID
      const link = ReportsService.taskReportDownloadURL(this.$store.state.downloadStatus.reportUUID)
      // console.log('downloadReport. ', link)
      window.open(link, '_blank')
      this.$store.dispatch('registerDownloadComplete')
    }
  }

}
</script>

<style scoped>

div.downloadStatus {
  display: inline-block;
  float: left;
}

/*.surveyTitleText {*/
/*  text-transform: none;*/
/*}*/

div.downloadButton {
  margin-right: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, .87);
}

div.buttonLabel {
  /*font-size: 13px;*/
  font-size: calc((13/16)*1rem);
  color: rgba(0, 0, 0, .87);
}

.downloadlist {
  min-width: 10em;
}


.pdfLink {
  text-decoration: none;
  color: black !important;
}

</style>
